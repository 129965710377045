<template >
    <div>
        <div class="order" style="height:60vh; ">
            <div class="printable" style=" ">
                <div style=" padding: 0 5px; width: 33.33%; ">
                    <b>PZ</b> Przyjęcie zewnętrzne
                    <table>
                        <tr>
                            <td style="width: 100px">Index:</td>
                            <td>{{ getWarehouseDetails.index }}</td>
                        </tr>
                        <tr style="height: 60px">
                            <td style="width: 100px">Miejsce i data wystawienia PZ:</td>
                            <td>{{ getWarehouseDetails.wzDate }}</td>
                        </tr>
                        <tr>
                            <td style="width: 100px">Data i czas sprzedaży:</td>
                            <td>{{ getWarehouseDetails.weighingDate }}</td>
                        </tr>
                    </table>

                    <table style="margin-top: 16px">
                        <tr>
                            <td>Pojazd:</td>
                            <td>
                                {{
                                getWarehouseDetails.carId
                                ? getWarehouseDetails.carId.model +
                                ' ' +
                                getWarehouseDetails.carId.plate
                                : getWarehouseDetails.carPlate
                                }}
                            </td>
                        </tr>
                        <tr>
                            <td>Kierowca:</td>
                            <td>{{ getWarehouseDetails.carDriver }}</td>
                        </tr>
                    </table>
                </div>
                <div style="padding: 0 5px; width: 33.33%">
                    <b>Sprzedawca</b>
                    <table>
                        <tr style="height: 60px">
                            <td>Nazwa:</td>
                            <td>{{ getWarehouseDetails.contactId ? getWarehouseDetails.contactId.name : ""}}</td>
                        </tr>
                        <tr style="height: 60px">
                            <td>Adres:</td>
                            <td>{{correctAddress(getWarehouseDetails.contactId)}}</td>
                        </tr>
                        <tr>
                            <td>Nr tel.:</td>
                            <td>{{ getWarehouseDetails.contactId ? getWarehouseDetails.contactId.phoneNumber : '' }}</td>
                        </tr>
                        <tr>
                            <td>E-mail:</td>
                            <td>{{ getWarehouseDetails.contactId ? getWarehouseDetails.contactId.email :'' }}</td>
                        </tr>
                        <tr>
                            <td>NIP:</td>
                            <td>{{ getWarehouseDetails.contactId ? getWarehouseDetails.contactId.NIP :'' }}</td>
                        </tr>
                    </table>
                </div>
                <div style="padding: 0 5px; width: 33.33%">
                    <b>Nabywca</b>
                    <table>
                        <tr style="height: 60px">
                            <td>Nazwa:</td>
                            <td>{{ getWarehouseDetails.company }}</td>
                        </tr>
                        <tr style="height: 60px">
                            <td>Adres:</td>
                            <td>{{getWarehouseDetails.companyAddress}}</td>
                        </tr>
                        <tr>
                            <td>Nr tel.:</td>
                            <td>{{getWarehouseDetails.companyPhoneNumber}}</td>
                        </tr>
                        <tr>
                            <td>E-mail:</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>NIP:</td>
                            <td>{{getWarehouseDetails.companyNIP}}</td>
                        </tr>
                    </table>
                </div>
                <div class="second-row">
                    <div cols="12" md="12">
                        <table>
                            <tr>
                                <th>Towar</th>
                                <th>jm.</th>
                                <th>Tara</th>
                                <th>Netto</th>
                                <th>Brutto</th>
                            </tr>
                            <tr>
                                <td>{{ getWarehouseDetails.materialType ? getWarehouseDetails.materialType.name : '' }}</td>
                                <td>{{ getWarehouseDetails.materialType ? getWarehouseDetails.materialType.unit : '' }}</td>
                                <td>{{ Number(getWarehouseDetails.tareWeight).toFixed(2) }}</td>
                                <td>
                                    {{
                                    Number(Number(getWarehouseDetails.grossWeight) - Number(getWarehouseDetails.tareWeight)).toFixed(2)
                                    }}
                                </td>
                                <td>{{ Number(getWarehouseDetails.grossWeight).toFixed(2) }}</td>
                            </tr>
                        </table>

                        <span style="font-size:11px">
                            {{
                            getWarehouseDetails.description
                            }}
                        </span>
                    </div>
                </div>
                <div class="third-row">
                    <div cols="12" md="12">
                        <table>
                            <tr>
                                <th>Wydał</th>
                                <th>Pobrał</th>
                                <th>Odebrał</th>
                            </tr>
                            <tr style="height : 32px">
                                <td>{{getWarehouseDetails.createdBy ? getWarehouseDetails.createdBy.name + " " + getWarehouseDetails.createdBy.lastname : ""}}</td>
                                <td>{{getWarehouseDetails.carDriver}}</td>
                                <td></td>
                            </tr>
                        </table>

                        <span class="accept">
                            Oświadczam, że samochód został załadowany ilościowo zgodnie
                            z moją dyspozycją. Akceptuję ilość towaru załadowanego na
                            samochód i biorę na siebie wszelką odpowiedzialność za
                            wynikłe z powyższego zdarzenia.
                        </span>
                    </div>
                    <div class="signature">
                        <br />....................................
                        <br />podpis
                        pobierającego
                    </div>
                </div>
            </div>
        </div>
        <v-btn dark class="buttons--add no-print order__btn" fab small @click="print()">
            <v-icon size="18" dark>mdi-printer</v-icon>
        </v-btn>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters(["getWarehouseDetails"]),
    },
    methods: {
        print() {
            window.print();
        },
        correctAddress(contactId) {
            let address = "";
            if (contactId) {
                address = `${contactId.street} ${contactId.houseNumber} ${
                    contactId.apartmentNumber
                        ? "/" + contactId.apartmentNumber
                        : ""
                } ${contactId.postCode} ${contactId.city}`;
            }
            return address;
        },
    },
};
</script>

<style scoped>
.printable {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
table {
    margin-top: 4px;
    border-collapse: collapse;
    table-layout: auto;
    width: 100%;
}
td {
    font-weight: 500;
    border: 1px solid black;
    padding: 8px;
    font-size: 11px;
    border-collapse: collapse;
    word-break: normal;
}
.order {
    padding: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.accept {
    font-size: 11px;
    margin-top: 15px;
    display: block;
    width: 99.88%;
}
.signature {
    position: absolute;
    right: 40px;
    font-size: 11px;
    bottom: -50px;
}
.second-row {
    margin-top: 40px;
    padding: 0 5px;
    width: 99.88%;
}
.third-row {
    margin-top: 25px;
    padding: 0 5px;
    position: relative;
    width: 99.88%;
}
@media print {
    .order {
        height: 100%;
        box-shadow: none;
        top: 0;
        font-size: 11px;
        width: 100%;
        position: absolute;
        transform: translate(-50%, 0%);
    }
    .accept {
        font-size: 10px;
        margin-top: 15px;
    }
    .second-row {
        margin-top: 10px;
        width: 740px;
    }
    .third-row {
        margin-top: 25px;
        position: relative;
        width: 740px;
    }
    .signature {
        position: absolute;
        right: 40px;
        font-size: 11px;
        bottom: -50px;
    }
    .no-print {
        display: none !important;
    }
}
</style>
